import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/layout.js";
import SEO from '../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Velkommen" mdxType="SEO" />
    <h1>{`Velkommen`}</h1>
    <p>{`Ja, velkommen til familien Høghs hjem på nettet. Dette er en privat side med det ene formål at være vores forum på nettet. Der er endnu ikke meget indhold at finde her på siden, mest fordi siden som sådan kun er et hobbyprojekt og vi har lige så travl en hverdag som de fleste andre danskere, så kun med tiden vil denne her side tage form som en resource på nettet. Måske vil vi bruge den til at offentliggøre vores tophemlige tzatziki-opskrift. Eller handle heste. Eller udsprede kattebilleder. Eller...`}</p>
    <p>{`Så hvis det kunne have din interesse, så tag et kig rundt omkring :)`}</p>
    <p>{`Med venlig hilsen`}<br parentName="p"></br>{`
`}{`Mikkel Høgh`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      